import React, {useState, useEffect} from "react"
import {Container,Table, Dropdown, Row, Col, Button} from 'react-bootstrap';
import { Link, navigate } from "gatsby";

import NextArw from "../../assests/images/grey_next_arw.svg";
import {config} from '../Common/constant';

import { SELLER_PROPERTIES } from "../../queries/common_use_query";

const SellerRightSide = (props) => {

    const [property_list, setPropertyList] = useState([]);
    
    const { loading, error, data } = SELLER_PROPERTIES(props.get_seller_id);
    useEffect(() => {
        setPropertyList(data && data.properties && data.properties.length > 0 && data.properties);
    }, [data]);
    
    return (
    <> 
        <Col lg={4} md={4}>
            <div className="btns_section">
                <Link to={config.custom_present} className="btn btn-primary">create custom Presentation</Link>
                <Link to={config.preval_present} className="btn btn-primary">create pre-val presentation</Link>
                {/* <Button variant="primary" type="button">book property launch</Button> */}
            </div>
            {/* {
                property_list && property_list.length > 0 && (
                    <div className="card-block property_block">
                        <h3>Properties <span className="count_cls">{property_list.length}</span></h3>
                        <ul className="mt-4">
                            {
                                property_list.map((property, index) => {
                                    return(
                                        <li><Link to={`${config.property_details}/${property.id}`}><strong>{property.address}</strong><br /><small>Valuation Booked</small> <img className="nextArw" src={NextArw} alt="" /></Link></li>
                                    )                            
                                })
                            }
                        </ul>
                    </div>
                )
            } */}
            
        </Col>   
    </>
    )
}

export default SellerRightSide
