import React, {useEffect, useState} from "react"
import {Container,Table, Dropdown, Row, Col, Button} from 'react-bootstrap';
import { Link, navigate } from "gatsby";
import HTMLReactParser from 'html-react-parser';
import dateFormat from 'dateformat';


import LeftNav from "../components/Common/leftNav";
import SEO from "../components/seo"
import SellerRightSide from "../components/modules/seller-right-side"
import PropertyRightSide from "../components/modules/property-right-side"
import Seller_Details from "../components/modules/seller_details"
import SellerTab from "../components/modules/seller-tab"
import PropertyTab from "../components/modules/property-tab"
import { useContainerContext } from "../store/ContainerContext";


import { SELLER_DETAILS } from "../queries/common_use_query";

const SellerDetails = (props) => {

    let path_name = (props.location.pathname).split('/');
    let get_seller_id = path_name[path_name.length-1];

    const context = useContainerContext();
    const { seller_data, property_data } = context.state;
    const { sellerInfoDispatch } = context.actions;

    // const { loading, error, data } = SELLER_DETAILS(get_seller_id);

    // useEffect(() => {
    //     data && data.sellers && data.sellers.length > 0 && sellerInfoDispatch(data.sellers[0]);
    //     data && data.sellers && data.sellers.length > 0 && localStorage.setItem('seller_details', JSON.stringify(seller_data));
    // }, [data]);



    //console.log("seller_data", seller_data)
return(
    <>
      <SEO title="Seller Contact Details" description="Seller Contact Details" />
      
      <div className="dashboard-wrapper">
        <LeftNav page_name="Seller Details" />
        {/* sidebar */}

        <div className="dashboard-content seller_cnt">
          <Container>
              <div className="content-wrapper ">                    

                    {/* <SellerTab tab_name="Seller Details" get_seller_id={get_seller_id}/> */}
                    <PropertyTab tab_name="Seller Details" get_seller_id={get_seller_id} />
                    {/* Tab Content */}

                    <div className="sell_details">
                        <Row>
                            <Col lg={12} md={12}>
                                <Row>
                                                                        
                                    <Col lg={8} md={8}>
                                        {seller_data && <Seller_Details seller_data={seller_data} property_data={property_data}  /> }
                                    </Col>
                                    
                                    <PropertyRightSide get_seller_id={get_seller_id} />
                                </Row>
                            </Col>    
                        </Row>
                    </div>
              </div>
              
          </Container>
          {/* container */}
        </div>
        {/* dashboard-content */}
      </div>
    </>
  )

}

export default SellerDetails